import React from "react";

import { useTranslation, Trans } from "react-i18next";

import bg from '../../img/Home/bg.png'
import btc from '../../img/Home/btc.png'
import vector from '../../img/Home/vector.png'
import iphone from '../../img/Home/iphone.png'

import Fade from 'react-reveal/Fade'

function Main() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-[#EAEAE5] overflow-hidden relative">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[40px] xl:pt-[100px] xl:h-[700px] relative z-50">
                <div className="flex items-center gap-[10px]">
                    <p className="manrope font-[700] text-[40px] xl:text-[50px] text-[#FF5131] uppercase">{t('main.1')}</p>
                    <img src={btc} alt="" className="xl:h-[116px] xl:w-[373px] w-[111px] h-[34px]"/>
                </div>
                <div className="flex items-center gap-[25px]">
                    <img src={vector} alt="" className="hidden xl:block w-[116px] h-[116px]"/>
                    <p className="manrope font-[700] text-[40px] xl:text-[50px] text-[#000] uppercase spanmain"><Trans>{t('main.2')}</Trans></p>
                </div>
                <p className="max-w-[680px] mt-[40px] leading-[1.4] roboto text-[20px] xl:text-[40px]">{t('main.3')}</p>
                <a href="https://user.desc-acc.net/signup">
                    <div className="button_main mt-[60px]">
                        <p className="text-white roboto text-[16px] xl:text-[20px] uppercase font-[5600]">{t('main.4')}</p>
                    </div>
                </a>
            </div>
            <Fade right>
                <img src={iphone} alt="" className="xl:absolute top-[50px] right-0 z-50 relative"/>
            </Fade>
            <img src={bg} alt="" className="absolute top-0 left-1/2 -translate-x-1/2 h-full w-[1280px] object-cover"/>
        </div>
    );
}

export default Main;
